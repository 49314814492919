<template>
  <div class="header-wrapper">
    <div
      :class="isStagingPreview ? 'header-spacer--staging' : 'header-spacer'"
    ></div>
    <header v-track-click-group="trackingClickGroup" class="header">
      <AppStagingBanner v-if="isStagingPreview" />
      <div class="section">
        <div class="navbar container">
          <div class="navbar-brand">
            <a href="/">
              <img
                class="navbar-brand__logo"
                :src="logoSrc"
                :alt="logoAltText"
                height="36"
                width="40"
              />
            </a>
          </div>
          <nav v-if="!hideMenu && !hideNavMenu" class="navbar-menu">
            <!-- desktop nav menu -->
            <ul class="navbar-menu-list">
              <li
                v-for="item in filteredNav"
                :key="item._uid"
                v-editable="item"
                class="navbar-menu-list__item"
                :class="{
                  'navbar-menu-list__item--active':
                    dropdownMenuActive === item.navTitle[0].name
                }"
                @mouseover="
                  item.navLinks.length > 0
                    ? hoverNav(item.navTitle[0].name)
                    : null
                "
                @mouseleave="
                  item.navLinks.length > 0
                    ? leaveNav(item.navTitle[0].name)
                    : null
                "
              >
                <component
                  :is="item.navTitle[0].link ? 'NavLink' : 'button'"
                  v-track="item.tracking"
                  class="navbar-menu-list__item-link"
                  :link="item.navTitle[0].link ? item.navTitle[0].link : null"
                  :link-type="item.navTitle[0].component"
                  :aria-expanded="
                    dropdownMenuActive === item.navTitle[0].name || null
                  "
                  aria-haspopup="true"
                  :data-testid="
                    item.navTitle[0].link
                      ? null
                      : `${kebabCase(item.navTitle[0].name)}`
                  "
                  @keydown.enter="openDropdown(item.navTitle[0].name)"
                >
                  <span
                    v-show="item.navTitle[0]"
                    class="navbar-menu-list__text-wrapper"
                  >
                    <span class="navbar-menu-list__text">
                      {{ item.navTitle[0].name }}
                    </span>
                  </span>
                </component>
                <!-- desktop submenu dropdown -->
                <div
                  v-show="
                    dropdownMenuActive === item.navTitle[0].name &&
                      item.navLinks.length > 0
                  "
                  class="navbar-submenu"
                >
                  <ul class="navbar-submenu__list" role="menu">
                    <li
                      v-for="link in item.navLinks"
                      :key="link._uid"
                      v-editable="link"
                      class="navbar-submenu__item"
                      role="menuitem"
                    >
                      <NavLink
                        v-track="link.tracking"
                        :link="link.link"
                        :link-type="link.component"
                        class="navbar-submenu__item-link"
                        @click.native="desktopNavItemClicked"
                      >
                        {{ link.name }}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
          <div v-if="!hideMenu" class="navbar-cta">
            <div v-if="filteredNavButtons">
              <NavLink
                v-for="item in filteredNavButtons"
                :key="item._uid"
                v-track="item.tracking"
                :link-type="item.navTitle[0].component"
                :link="item.navTitle[0].link"
                class="menu-button get-a-quote-button button-link"
              >
                {{ item.navTitle[0].name }}
              </NavLink>
            </div>
            <NavLink
              v-if="userStatus.userSignedIn && userStatus.admin"
              variant="secondary"
              class="menu-button is-secondary"
              link="/admin"
              link-type="NavItemAccount"
              :routing-component="AppButton"
            >
              <i class="icon icon-tool"></i>
            </NavLink>
            <AppButton
              v-if="userStatus.userSignedIn"
              :tracking="signOutLink.tracking"
              class="menu-button is-secondary"
              @click="$emit('logout')"
            >
              {{ signOutLink.name }}
            </AppButton>
            <NavLink
              v-else
              v-track="logInLink.tracking"
              :link="logInLink.link"
              :link-type="logInLink.component"
              class="menu-button is-secondary"
              :routing-component="AppButton"
            >
              {{ logInLink.name }}
            </NavLink>
          </div>
          <div class="mobile-nav-toggle" @click="toggleMobileMenu">
            <button v-if="mobileNavOpen" class="menu-button">
              Close
              <i class="mobile-nav-toggle__icon-close icon-close icon"> </i>
            </button>
            <button v-else class="menu-button is-secondary">
              Menu
            </button>
          </div>
        </div>
        <!-- mobile nav menu -->
        <div
          v-if="mobileNavOpen && !hideMenu"
          class="mobile-nav"
          :style="{
            color: '#1A1A1A',
            backgroundColor: '#FFFFFF'
          }"
        >
          <div v-if="submenuSelected" class="mobile-nav__back-button-wrapper">
            <button
              v-show="submenuSelected"
              class="mobile-nav__back-button"
              @click="clickBack"
            >
              <i class="mobile-nav__back-button-icon icon icon-ctrl-left"></i>
              <span>Back</span>
            </button>
          </div>
          <ul v-if="!hideNavMenu" class="mobile-nav-list">
            <li
              v-for="item in mobileNavLinks"
              :key="item._uid"
              class="mobile-nav-list__item caps"
            >
              <NavLink
                :is="item.link ? 'NavLink' : 'span'"
                v-if="item.link"
                v-track="item.tracking"
                class="mobile-nav-list__item-link"
                :class="{
                  'mobile-nav-list__item-link--submenu': submenuSelected
                }"
                :link="item.link"
                :link-type="item.component"
                @click.native="mobileNavItemClicked"
              >
                <span v-if="item" class="mobile-nav-list__item-text">
                  {{ item.name }}
                </span>
              </NavLink>
              <span
                v-else
                v-track="
                  item.tracking && item.tracking.length
                    ? item.tracking
                    : item.name
                "
                class="mobile-nav-list__item-link"
                :class="{
                  'mobile-nav-list__item-link--submenu': submenuSelected
                }"
                @click="clickMobileNav(item.name)"
              >
                <span v-if="item" class="mobile-nav-list__item-text">
                  {{ item.name }}
                </span>
              </span>
            </li>
          </ul>
          <div class="mobile-nav__cta">
            <NavLink
              v-for="item in filteredNavButtons"
              :key="item._uid"
              v-track="item.tracking"
              :link-type="item.navTitle[0].component"
              :link="item.navTitle[0].link"
              class="menu-button mobile-nav__cta-button button-link"
            >
              {{ item.navTitle[0].name }}
            </NavLink>
            <AppButton
              v-if="userStatus.userSignedIn"
              :tracking="signOutLink.tracking"
              class="mobile-nav__cta-button menu-button is-secondary"
              variant="secondary"
              @click="$emit('logout')"
            >
              {{ signOutLink.name }}
            </AppButton>
            <NavLink
              v-else
              v-track="logInLink.tracking"
              class="mobile-nav__cta-button menu-button"
              variant="secondary"
              :link="logInLink.link"
              :link-type="logInLink.component"
              :routing-component="AppButton"
            >
              {{ logInLink.name }}
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import AppStagingBanner from "./AppStagingBanner.vue"
import AppButton from "./AppButton"
import AppLink from "./AppLink.vue"
import NavLink from "./NavLink.vue"
import stubDirectives from "../mixins/stubDirectives"
import preventScrollMixin from "./../mixins/preventScrollMixin"

import renewalStatusHelper from "../helpers/renewalStatusHelper"

export default {
  components: {
    AppStagingBanner,
    AppButton,
    AppLink,
    NavLink
  },
  mixins: [stubDirectives, preventScrollMixin],
  props: {
    nav: {
      type: Array,
      default: () => []
    },
    logInLink: {
      type: Object,
      default: () => {}
    },
    signOutLink: {
      type: Object,
      default: () => {}
    },
    accountNumber: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: () => ({})
    },
    userStatus: {
      type: Object,
      default: () => {}
    },
    logoImage: {
      type: Object,
      default: () => {}
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    hideNavMenu: {
      type: Boolean,
      default: false
    },
    trackingClickGroup: {
      type: [Array, Object],
      default: null
    },
    isProspectSwitchJourneyEnabled: {
      type: Boolean,
      default: false
    },
    isPromoteRenewalEnabled: {
      type: Boolean,
      default: true
    },
    deploymentUrl: {
      type: String,
      default: null
    },
    moveOutRecordFetched: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      AppButton,
      submenu: [],
      submenuSelected: "",
      mobileNavOpen: false,
      dropdownMenuActive: "",
      timer: null
    }
  },
  computed: {
    isStagingPreview() {
      return this.deploymentUrl?.includes("staging")
    },
    filteredNav() {
      if (!this.nav) return []
      return this.nav
        .filter(navTitle => this.filterNavTitle(navTitle))
        .filter(navTitle => !!!navTitle.showAsButton)
        .map((navMenu, _uid) => {
          return {
            navTitle: navMenu.navTitle,
            navLinks: navMenu.navLinks.filter(navLink =>
              this.checkAccountRoute(navLink.link)
            ),
            backgroundColor: navMenu.backgroundColor,
            fontColor: navMenu.fontColor,
            _editable: navMenu._editable,
            _uid
          }
        })
    },
    mobileNavLinks() {
      if (this.submenuSelected) {
        return this.filteredNav.find(
          element => element.navTitle[0].name == this.submenuSelected
        ).navLinks
      } else {
        return this.filteredNav.map(link => link.navTitle[0])
      }
    },
    filteredNavButtons() {
      if (!this.nav) return []
      return this.nav
        .filter(navTitle => this.filterNavTitle(navTitle))
        .filter(navTitle => navTitle.showAsButton)
        .map((navMenu, _uid) => {
          return {
            navTitle: navMenu.navTitle,
            _uid
          }
        })
    },
    logoSrc() {
      if (this.logoImage && this.logoImage.filename) {
        return this.logoImage.filename
      }
      return require(`../images/logo-so-energy-black.svg`)
    },
    logoAltText() {
      if (this.logoImage && this.logoImage.alt) {
        return this.logoImage.alt
      }
      return "So Energy logo"
    }
  },
  watch: {
    mobileNavOpen: {
      handler(value) {
        this.preventBodyScroll(value)
      }
    }
  },
  methods: {
    filterNavTitle(route) {
      let showNavTile = this.userStatus.userSignedIn
        ? !route.hideWhenLoggedIn
        : !route.hideWhenLoggedOut

      const validLink =
        route.navTitle && route.navTitle[0] && route.navTitle[0].link

      if (
        !this.isProspectSwitchJourneyEnabled &&
        validLink &&
        route.navTitle[0].link === "/get-a-quote"
      ) {
        showNavTile = false
      }

      if (
        this.moveOutRecordFetched &&
        validLink &&
        route.navTitle[0].link === "/moving-home"
      ) {
        showNavTile = false
      }

      if (
        !this.moveOutRecordFetched &&
        validLink &&
        route.navTitle[0].link === "/move-out/status"
      ) {
        showNavTile = false
      }

      return showNavTile
    },
    checkAccountRoute(route) {
      const user = this.userStatus

      switch (route) {
        case "/accounts/renew":
        case "/renew":
          return (
            this.isPromoteRenewalEnabled &&
            user.accountCanRenew &&
            !user.onVariableContract &&
            !renewalStatusHelper.hasPendingRenewal(
              this.user,
              this.accountNumber
            )
          )
        case "/accounts/renew?fixed-contract":
        case "/renew?fixed-contract":
          return (
            this.isPromoteRenewalEnabled &&
            user.accountCanRenew &&
            user.onVariableContract &&
            !renewalStatusHelper.hasPendingRenewal(
              this.user,
              this.accountNumber
            )
          )
        case "/accounts/balance":
        case "/balance":
          return user.accountUnlocked
        case "/accounts/readings":
        case "/readings":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/usage":
        case "/usage":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/edit":
        case "/personal-details":
          return true
        case "/accounts/tariff-information":
        case "/tariff":
          return !user.accountRestricted
        case "/accounts/payments":
        case "/payments":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/refer":
        case "/refer":
          return !user.blacklistedVoucher && !user.referDisabledGlobally
        case "/accounts/change":
        case "/choose":
          return user.multipleAccounts
        case "/accounts/smart_meter_portal":
          return (
            user.eligibleForSmartMeter && user.eligibleForSmartMeter.eligible
          )
        default:
          return true
      }
    },
    toggleMobileMenu() {
      this.mobileNavOpen = !this.mobileNavOpen
      this.submenuSelected = ""
    },
    clickMobileNav(submenu, closeMenu) {
      this.submenuSelected = submenu
    },
    desktopNavItemClicked() {
      setTimeout(() => {
        this.dropdownMenuActive = ""
      }, 200)
    },
    mobileNavItemClicked() {
      setTimeout(() => {
        this.mobileNavOpen = false
      }, 300)
    },
    clickBack() {
      this.submenuSelected = ""
    },
    hoverNav(dropdown) {
      clearTimeout(this.timer)
      this.dropdownMenuActive = dropdown
    },
    leaveNav() {
      this.timer = setTimeout(() => {
        this.dropdownMenuActive = ""
      }, 500)
    },
    openDropdown(dropdown) {
      if (this.dropdownMenuActive) {
        this.dropdownMenuActive = ""
      } else {
        this.dropdownMenuActive = dropdown
      }
    },
    kebabCase(name) {
      return name
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
%button-no-styling {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  text-align: center;

  @include xl {
    display: none;
  }

  &:hover,
  &:focus {
    background: transparent;
    outline: none;
  }
}

.header-spacer {
  height: 74px;

  @include xl {
    height: 4.5rem;
  }

  &--staging {
    height: 112px;
  }
}

.header {
  position: fixed;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: zindex(header);
  background-color: transparent;

  .navbar.container {
    height: fit-content;
    max-width: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xl {
      justify-content: unset;
      font-family: "Arial";
    }
  }

  .navbar-brand {
    font-size: 0;

    &__logo {
      height: 2.25rem;
      width: 2.5rem;
    }
  }

  .navbar-cta {
    display: none;

    @include xl {
      display: flex;
    }
  }

  .button-link {
    text-decoration: none;
  }

  .section {
    box-shadow: 0 4px 8px 0 rgba(182, 182, 182, 0.25);
    border-radius: 30px;
    padding: $space-2 $space-2 $space-2 $space-4;
    background-color: $white;
    margin: $space-2;
  }

  .mobile-nav-toggle {
    .menu-button {
      padding: $space-2 $space-4;
    }

    &__icon-close {
      width: 18px;
      height: 18px;
      padding-top: 2px;
      padding-left: $space-2;
    }

    @include xl {
      display: none;
    }
  }

  .mobile-nav {
    position: static;
    width: 100%;
    max-height: calc(100% - 4.5rem);
    height: unset;
    display: flex;
    flex-direction: column;
    margin-top: $space-2;
    color: $night;
    background-color: $day;

    @include xl {
      display: none;
    }

    &__back-button-wrapper {
      padding: 0;
      height: fit-content;
    }

    &__back-button {
      @extend %button-no-styling;

      font-size: $font-size-2;
      padding: $space-3 $space-2 0 $space-2;
      height: fit-content;
      font-weight: $weight-medium;
    }

    &__back-button-icon {
      margin-bottom: -2px;
      margin-right: 8px;
      font-weight: bold;
    }

    .mobile-nav-list {
      overflow-y: auto;
      padding: $space-2 $space-6;

      &__item-link {
        display: block;
        cursor: pointer;
        padding: $space-4 0;
        font-size: $font-size-4;
        font-weight: $weight-medium;
        border-bottom: 1px solid $blue-100;
        text-transform: initial;
        color: $black;

        &:hover {
          text-decoration: none;
        }
      }

      &__item-text {
        cursor: pointer;
      }
    }

    &__cta {
      padding: $space-6 $space-4 $space-4;

      .button-link {
        width: 100%;
        margin-bottom: $space-3;
      }
    }

    &__cta-button {
      width: 100%;
      text-transform: uppercase;
    }
  }

  .navbar-menu {
    flex-grow: 1;
    display: none;

    @include xl {
      display: block;
    }

    .navbar-menu-list {
      @include xl {
        display: flex;
        gap: $space-4;
        justify-content: flex-end;
        padding-right: 1rem;
      }

      &__item-link {
        font-family: "Arial";
        font-weight: $weight-normal;
        font-size: $font-size-1;
        background-color: transparent;
        border: 0;
        color: $black;
        line-height: $line-height-4;
        text-transform: initial;
        border-radius: 30px;
        display: flex;
        padding: $space-2 $space-6;
        cursor: pointer;

        &:hover {
          border-color: $new-design-secondary-200;
          background-color: $new-design-secondary-200;
          text-decoration: none;
        }
      }
    }
  }

  .button-link {
    margin-right: $space-4;
  }

  .navbar-submenu {
    font-family: "Founders Grotesk", "Arial";
    position: absolute;
    padding-top: $spacing-4;

    &__item {
      padding: $space-1 $space-4;
      display: flex;
      border-radius: 30px;

      &:hover {
        background-color: $blue-100;
      }
    }

    &__list {
      padding: $space-4;
      background-color: $white;
      border-radius: 16px;
      border: 1px solid $blue-100;
      box-shadow: 0 8px 16px 0 rgba(182, 182, 182, 0.25);
      display: flex;
      flex-direction: column;
      gap: $space-2;
      width: 324px;
    }

    &__item-link {
      font-size: $font-size-3;
      font-weight: $weight-normal;
      padding: 0;
      color: black;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .menu-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: "Arial";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    vertical-align: top;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    border: $button-border-width solid $button-background-color;
    border-radius: 30px;
    box-shadow: none;
    color: $black;
    font-size: $font-size-1;
    font-weight: $weight-normal;
    text-align: center;
    background-color: $button-background-color;
    line-height: $line-height-4;
    padding: $space-2 $space-6;

    &:hover {
      border-color: $green-200;
      background-color: $green-200;
    }

    &:active {
      border-color: $green-600;
      background-color: $green-600;
    }

    &:focus-visible {
      box-shadow: 0px 0px 0px 2px $green-800;
      border: 2px solid $white;
      outline: none;
    }

    &.is-secondary {
      border: 1px solid $black;
      background-color: $white;

      &:active {
        color: $white;
        background-color: $black;
        border-color: $black;
      }

      &:hover:not(:active) {
        color: $black;
        background-color: #faf6f3;
      }

      &:focus-visible {
        box-shadow: 0 0 0px 1px $green-600;
        border-color: $green-600;
      }
    }

    &.is-tertiary {
      background-color: $white;
      border-color: $white;
      color: $black;

      &:hover {
        border-color: $green-200;
        background-color: $green-200;
      }

      &:active {
        border-color: $green-600;
        background-color: $green-600;
      }

      &:focus-visible {
        border-color: $green-600;
        box-shadow: none;
      }
    }
  }
}
</style>

<style lang="scss">
// Helper offsetting elements used by anchor links to adjust for fixed header
// TODO: Replace with `scroll-padding-top` once iOS Support rolled out
.anchor-target {
  display: block;
  height: 5rem;
  margin-top: -5rem;
  visibility: hidden;
}
</style>
