<template>
  <Modal
    v-if="pageContent"
    ref="login-modal"
    :is-modal-open="show"
    :show-close-icon="false"
  >
    <template #header>
      <h4>
        {{ pageContent.title }}
      </h4>
    </template>
    <template #body>
      <p>{{ pageContent.description }}</p>
    </template>
    <template #footer>
      <NavButton
        id="login-modal__button"
        to="/login?redirect=fe-smbp/smart-meter-appointment"
        :target-project="PROJECTS.AccountApp"
      >
        Log in
      </NavButton>
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import NavButton from "@soenergy/frontend-library/src/components/NavButton"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import loginModalContent from "soenergy-cms-loader!?path=smbp/login-modal"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"

export default {
  components: {
    Modal,
    NavButton,
  },
  mixins: [cmsPreviewMixin({ story: loginModalContent })],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    PROJECTS() {
      return PROJECTS
    },
  },
}
</script>

<style lang="scss">
#login-modal__button {
  width: 100%;
  border-radius: 0 0 4px 4px;
}
</style>
