import { createStore } from "vuex"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { sessionStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import appointment from "./modules/appointment"
import homeDuringAppointment from "./modules/homeDuringAppointment"
import * as bookingDetails from "./modules/bookingDetails"
import { actions, getters, mutations } from "./untested/store"

dayjs.extend(isBetween)

export default createStore({
  modules: {
    appointment,
    homeDuringAppointment,
    bookingDetails,
  },
  state: {
    bookingData: [],
    customerId: sessionStore.getItem("customerId") || null,
    juniferAccountId: sessionStore.getItem("juniferAccountId") || null,
    authenticated: true,
    loading: false,
    showAuthError: false,
    junifer: false,
    accounts: null,
    meterEligibility: null,
    eligibility: null,
    campaignStatus: null,
    submitStatus: null,
    submitError: null,
    isShortBookingJourneyEnabled: null,
  },
  mutations: {
    ...mutations,
    // Tested mutations below
  },
  actions: {
    ...actions,
    // Tested actions below
  },
  getters: {
    ...getters,
    // Tested getters below
  },
})
