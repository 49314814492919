<template>
  <div>
    <div
      :class="isStagingPreview ? 'header-spacer--staging' : 'header-spacer'"
    ></div>
    <header v-track-click-group="trackingClickGroup" class="header">
      <AppStagingBanner v-if="isStagingPreview" />
      <div class="section">
        <div class="navbar container">
          <div class="navbar-brand">
            <component
              :is="linkLogoToMainWebsite ? `NavLink` : `AppLink`"
              :target-project="logoLinkTargetProject"
              link="/"
            >
              <img
                class="navbar-brand__logo"
                :src="logoSrc"
                :alt="logoAltText"
                height="40"
                width="54"
              />
            </component>
          </div>
          <nav v-if="!hideMenu && !hideNavMenu" class="navbar-menu">
            <!-- desktop nav menu -->
            <ul class="navbar-menu-list">
              <li
                v-for="item in filteredNav"
                :key="item._uid"
                v-editable="item"
                class="navbar-menu-list__item"
                :class="{
                  'navbar-menu-list__item--active':
                    dropdownMenuActive === item.navTitle[0].name
                }"
                @mouseover="
                  item.navLinks.length > 0
                    ? hoverNav(item.navTitle[0].name)
                    : null
                "
                @mouseleave="
                  item.navLinks.length > 0
                    ? leaveNav(item.navTitle[0].name)
                    : null
                "
              >
                <component
                  :is="item.navTitle[0].link ? 'NavLink' : 'a'"
                  v-track="item.tracking"
                  class="navbar-menu-list__item-link"
                  :link="item.navTitle[0].link ? item.navTitle[0].link : null"
                  :link-type="item.navTitle[0].component"
                  :href="
                    item.navTitle[0].link
                      ? item.navTitle[0].link
                      : 'javascript:;'
                  "
                  :aria-expanded="
                    dropdownMenuActive === item.navTitle[0].name || null
                  "
                  aria-haspopup="true"
                  :data-testid="
                    item.navTitle[0].link
                      ? null
                      : `${kebabCase(item.navTitle[0].name)}`
                  "
                  @keydown.enter="openDropdown(item.navTitle[0].name)"
                >
                  <span
                    v-show="item.navTitle[0]"
                    class="navbar-menu-list__text-wrapper"
                  >
                    <span class="navbar-menu-list__text">
                      {{ item.navTitle[0].name }}
                    </span>
                    <i
                      v-if="item.navLinks.length > 0"
                      class="navbar-menu-list__icon icon"
                      :class="
                        dropdownMenuActive === item.navTitle[0].name
                          ? 'icon-chevron-up'
                          : 'icon-chevron-down'
                      "
                    >
                    </i>
                  </span>
                </component>
                <!-- desktop submenu dropdown -->
                <div>
                  <ul
                    v-show="
                      dropdownMenuActive === item.navTitle[0].name &&
                        item.navLinks.length > 0
                    "
                    class="navbar-submenu"
                    role="menu"
                    :style="{
                      backgroundColor: item.backgroundColor
                        ? item.backgroundColor
                        : '#309ED6',
                      color: item.fontColor ? item.fontColor : '#FFFFFF'
                    }"
                  >
                    <li
                      v-for="link in item.navLinks"
                      :key="link._uid"
                      v-editable="link"
                      class="navbar-submenu__item"
                      role="menuitem"
                    >
                      <NavLink
                        v-track="link.tracking"
                        :link="link.link"
                        :link-type="link.component"
                        class="navbar-submenu__item-link"
                        @click.native="desktopNavItemClicked"
                      >
                        {{ link.name }}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
          <div v-if="!hideMenu" class="navbar-cta">
            <NavLink
              v-if="userStatus.userSignedIn && userStatus.admin"
              variant="secondary"
              link="/admin"
              link-type="NavItemAccount"
              size="small"
              :routing-component="AppButton"
            >
              <i class="icon icon-tool"></i>
            </NavLink>
            <AppButton
              v-if="userStatus.userSignedIn"
              :tracking="signOutLink.tracking"
              variant="boldSecondary"
              size="small"
              @click="$emit('logout')"
            >
              {{ signOutLink.name }}
            </AppButton>
            <NavLink
              v-else
              v-track="logInLink.tracking"
              variant="boldSecondary"
              :link="logInLink.link"
              :link-type="logInLink.component"
              size="small"
              :routing-component="AppButton"
            >
              {{ logInLink.name }}
            </NavLink>
          </div>
          <div v-if="!hideMenu" class="mobile-nav-toggle">
            <button
              class="mobile-nav-toggle__button"
              aria-label="Close navigation menu"
              @click="toggleMobileMenu"
            >
              <i
                class="mobile-nav-toggle__icon-close icon"
                :class="mobileNavOpen ? 'icon-close' : 'icon-menu'"
              >
              </i>
            </button>
          </div>
        </div>
      </div>
      <!-- mobile nav menu -->
      <div
        v-if="mobileNavOpen && !hideMenu"
        class="mobile-nav"
        :style="mobileNavStyle"
      >
        <div class="mobile-nav__back-button-wrapper">
          <button
            v-show="submenuSelected"
            class="mobile-nav__back-button"
            @click="clickBack"
          >
            <i class="mobile-nav__back-button-icon icon icon-ctrl-left"></i>
            <span>Back</span>
          </button>
        </div>
        <ul v-if="!hideNavMenu" class="mobile-nav-list">
          <li
            v-for="item in mobileNavLinks"
            :key="item._uid"
            class="mobile-nav-list__item caps"
          >
            <NavLink
              :is="item.link ? 'NavLink' : 'span'"
              v-if="item.link"
              v-track="item.tracking"
              class="mobile-nav-list__item-link"
              :class="{
                'mobile-nav-list__item-link--submenu': submenuSelected
              }"
              :link="item.link"
              :link-type="item.component"
              @click.native="mobileNavItemClicked"
            >
              <span v-if="item" class="mobile-nav-list__item-text">
                {{ item.name }}
              </span>
            </NavLink>
            <span
              v-else
              v-track="
                item.tracking && item.tracking.length
                  ? item.tracking
                  : item.name
              "
              class="mobile-nav-list__item-link"
              :class="{
                'mobile-nav-list__item-link--submenu': submenuSelected
              }"
              @click="clickMobileNav(item.name)"
            >
              <span v-if="item" class="mobile-nav-list__item-text">
                {{ item.name }}
              </span>
            </span>
          </li>
        </ul>
        <div class="mobile-nav__cta">
          <AppButton
            v-if="userStatus.userSignedIn"
            :tracking="signOutLink.tracking"
            class="mobile-nav__cta-button"
            :variant="
              mobileNavStyle.color === '#1A1A1A'
                ? 'secondary'
                : 'invertedSecondary'
            "
            size="large"
            @click="$emit('logout')"
          >
            {{ signOutLink.name }}
          </AppButton>
          <NavLink
            v-else
            v-track="logInLink.tracking"
            class="mobile-nav__cta-button"
            :variant="
              mobileNavStyle.color === '#1A1A1A'
                ? 'secondary'
                : 'invertedSecondary'
            "
            size="large"
            :link="logInLink.link"
            :link-type="logInLink.component"
            :routing-component="AppButton"
          >
            {{ logInLink.name }}
          </NavLink>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import AppStagingBanner from "./AppStagingBanner.vue"
import AppButton from "./AppButton"
import AppLink from "./AppLink.vue"
import NavLink from "./NavLink.vue"
import stubDirectives from "../mixins/stubDirectives"
import preventScrollMixin from "./../mixins/preventScrollMixin"
import { PROJECTS } from "../config/projectRouting"

import renewalStatusHelper from "../helpers/renewalStatusHelper"

export default {
  components: {
    AppStagingBanner,
    AppButton,
    AppLink,
    NavLink
  },
  mixins: [stubDirectives, preventScrollMixin],
  props: {
    nav: {
      type: Array,
      default: () => []
    },
    logInLink: {
      type: Object,
      default: () => {}
    },
    signOutLink: {
      type: Object,
      default: () => {}
    },
    accountNumber: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: () => ({})
    },
    userStatus: {
      type: Object,
      default: () => {}
    },
    logoImage: {
      type: Object,
      default: () => {}
    },
    linkLogoToMainWebsite: {
      type: Boolean,
      default: true
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    hideNavMenu: {
      type: Boolean,
      default: false
    },
    trackingClickGroup: {
      type: [Array, Object],
      default: null
    },
    isProspectSwitchJourneyEnabled: {
      type: Boolean,
      default: false
    },
    isPromoteRenewalEnabled: {
      type: Boolean,
      default: true
    },
    deploymentUrl: {
      type: String,
      default: null
    },
    moveOutRecordFetched: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      AppButton,
      submenu: [],
      submenuSelected: "",
      mobileNavOpen: false,
      dropdownMenuActive: "",
      timer: null
    }
  },
  computed: {
    isStagingPreview() {
      return this.deploymentUrl?.includes("staging")
    },
    filteredNav() {
      if (!this.nav) return []
      return this.nav
        .filter(navTitle => this.filterNavTitle(navTitle))
        .map(navMenu => {
          return {
            navTitle: navMenu.navTitle,
            navLinks: navMenu.navLinks.filter(navLink =>
              this.checkAccountRoute(navLink.link)
            ),
            backgroundColor: navMenu.backgroundColor,
            fontColor: navMenu.fontColor,
            _editable: navMenu._editable,
            _uid: navMenu._uid
          }
        })
    },
    mobileNavLinks() {
      if (this.submenuSelected) {
        return this.filteredNav.find(
          element => element.navTitle[0].name == this.submenuSelected
        ).navLinks
      } else {
        return this.filteredNav.map(link => link.navTitle[0])
      }
    },
    mobileNavStyle() {
      const style = {
        backgroundColor: "#1A1A1A",
        color: "#FFFFFF"
      }

      if (this.submenuSelected) {
        const backgroundColor = this.filteredNav.find(
          element => element.navTitle[0].name == this.submenuSelected
        ).backgroundColor

        const fontColor = this.filteredNav.find(
          element => element.navTitle[0].name == this.submenuSelected
        ).fontColor

        if (backgroundColor) {
          style.backgroundColor = backgroundColor
        }

        if (fontColor) {
          style.color = fontColor
        }
      }
      return style
    },
    logoSrc() {
      if (this.logoImage && this.logoImage.filename) {
        return this.logoImage.filename
      }
      return require(`../images/logo-so-energy-black.svg`)
    },
    logoAltText() {
      if (this.logoImage && this.logoImage.alt) {
        return this.logoImage.alt
      }
      return "So Energy logo"
    },
    logoLinkTargetProject() {
      return this.linkLogoToMainWebsite ? PROJECTS.Website : null
    }
  },
  watch: {
    mobileNavOpen: {
      handler(value) {
        this.preventBodyScroll(value)
      }
    }
  },
  methods: {
    filterNavTitle(route) {
      let showNavTile = this.userStatus.userSignedIn
        ? !route.hideWhenLoggedIn
        : !route.hideWhenLoggedOut

      const validLink =
        route.navTitle && route.navTitle[0] && route.navTitle[0].link

      if (
        !this.isProspectSwitchJourneyEnabled &&
        validLink &&
        route.navTitle[0].link === "/get-a-quote"
      ) {
        showNavTile = false
      }

      if (
        this.moveOutRecordFetched &&
        validLink &&
        route.navTitle[0].link === "/moving-home"
      ) {
        showNavTile = false
      }

      if (
        !this.moveOutRecordFetched &&
        validLink &&
        route.navTitle[0].link === "/move-out/status"
      ) {
        showNavTile = false
      }

      return showNavTile
    },
    checkAccountRoute(route) {
      const user = this.userStatus

      switch (route) {
        case "/accounts/renew":
        case "/renew":
          return (
            this.isPromoteRenewalEnabled &&
            user.accountCanRenew &&
            !user.onVariableContract &&
            !renewalStatusHelper.hasPendingRenewal(
              this.user,
              this.accountNumber
            )
          )
        case "/accounts/renew?fixed-contract":
        case "/renew?fixed-contract":
          return (
            this.isPromoteRenewalEnabled &&
            user.accountCanRenew &&
            user.onVariableContract &&
            !renewalStatusHelper.hasPendingRenewal(
              this.user,
              this.accountNumber
            )
          )
        case "/accounts/balance":
        case "/balance":
          return user.accountUnlocked
        case "/accounts/readings":
        case "/readings":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/usage":
        case "/usage":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/edit":
        case "/personal-details":
          return true
        case "/accounts/tariff-information":
        case "/tariff":
          return !user.accountRestricted
        case "/accounts/payments":
        case "/payments":
          return !user.accountRestricted && user.accountUnlocked
        case "/accounts/refer":
        case "/refer":
          return !user.blacklistedVoucher && !user.referDisabledGlobally
        case "/accounts/change":
        case "/choose":
          return user.multipleAccounts
        case "/accounts/smart_meter_portal":
          return (
            user.eligibleForSmartMeter && user.eligibleForSmartMeter.eligible
          )
        default:
          return true
      }
    },
    toggleMobileMenu() {
      this.mobileNavOpen = !this.mobileNavOpen
      this.submenuSelected = ""
    },
    clickMobileNav(submenu, closeMenu) {
      this.submenuSelected = submenu
    },
    desktopNavItemClicked() {
      setTimeout(() => {
        this.dropdownMenuActive = ""
      }, 200)
    },
    mobileNavItemClicked() {
      setTimeout(() => {
        this.mobileNavOpen = false
      }, 300)
    },
    clickBack() {
      this.submenuSelected = ""
    },
    hoverNav(dropdown) {
      clearTimeout(this.timer)
      this.dropdownMenuActive = dropdown
    },
    leaveNav() {
      this.timer = setTimeout(() => {
        this.dropdownMenuActive = ""
      }, 500)
    },
    openDropdown(dropdown) {
      if (this.dropdownMenuActive) {
        this.dropdownMenuActive = ""
      } else {
        this.dropdownMenuActive = dropdown
      }
    },
    kebabCase(name) {
      return name
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
%button-no-styling {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  text-align: center;

  @include xl {
    display: none;
  }

  &:hover,
  &:focus {
    background: transparent;
    outline: none;
  }
}

.header-spacer {
  height: 4rem;
  @include xl {
    height: 5rem;
  }

  &--staging {
    height: 104px;
    @include xl {
      height: 120px;
    }
  }
}

.header {
  box-shadow: 0px 3px 6px 0px rgba($night, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $day;
  z-index: zindex(header);
}

.section {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;

  @include xl {
    height: 5rem;
  }
}

.navbar-brand {
  font-size: 0;

  &__logo {
    height: 2.5rem;
    width: auto;

    @include xl {
      height: 3.25rem;
    }
  }
}

.navbar-menu {
  height: 100%;
  display: none;

  @include xl {
    display: block;
  }
}

.navbar-menu-list {
  height: 100%;

  &__item {
    display: inline-block;
    height: 100%;

    &--active {
      .navbar-menu-list__item-link {
        color: $night;
      }

      .navbar-menu-list__text:after {
        opacity: 1;
      }
    }
  }

  &__item-link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1.25rem;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: bold;
    color: $night-light;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: $night;

      .navbar-menu-list__text:after {
        opacity: 1;
      }
    }
  }

  &__text-wraper {
    position: relative;
  }

  &__text {
    position: relative;

    &:after {
      content: "";
      background-color: currentColor;
      height: 2px;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: -2px;
      border-radius: 10px;
    }
  }

  &__icon {
    font-weight: bold;
    margin-left: $spacing-1;
    vertical-align: middle;
  }
}

.navbar-cta {
  display: none;

  @include xl {
    display: block;
  }
}

.navbar-submenu {
  position: absolute;
  padding-left: 1.25rem;
  margin: $spacing-4 0;
  margin: 0 -100vw;
  padding: $spacing-4 100vw;

  &__item {
    padding: 0 $spacing-4;
  }

  &__item-link {
    display: inline-block;
    color: inherit;
    font-size: $size-5;
    font-weight: $weight-medium;
    line-height: 1;
    position: relative;
    padding: $spacing-1;

    &:after {
      content: "";
      background-color: currentColor;
      height: 2px;
      left: 4px;
      opacity: 0;
      position: absolute;
      right: 4px;
      bottom: 0px;
      border-radius: 10px;
    }

    &:hover {
      text-decoration: none;

      &:after {
        opacity: 1;
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  width: 100%;
  height: calc(100% - 4rem);
  top: 4rem;
  display: flex;
  flex-direction: column;
  background-color: $night;
  color: $day;

  @include xl {
    display: none;
  }

  &__back-button-wrapper {
    height: 4rem;
    flex-shrink: 0;
    padding: 0 calc(#{$section-horizontal-padding} - #{$spacing-4});

    @include sm {
      padding: 0 calc(#{$section-horizontal-padding-tablet} - #{$spacing-4});
    }
  }

  &__back-button {
    @extend %button-no-styling;
    color: inherit;
    height: 4rem;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    font-size: $size-6;
    padding: 17px 8px 19px 8px;

    @include sm {
      padding: 17px 8px 19px 8px;
    }
  }

  &__back-button-icon {
    margin-bottom: -2px;
    margin-right: 8px;
    font-weight: bold;
  }

  &__cta {
    padding: $spacing-4 $section-horizontal-padding;
    background-color: inherit;

    @include sm {
      padding: $section-horizontal-padding-tablet;
    }
  }

  &__cta-button {
    width: 100%;
    text-transform: uppercase;
  }
}

.mobile-nav-list {
  height: 100%;
  padding: 0 $section-horizontal-padding;
  overflow-y: scroll;

  @include sm {
    padding: 0 $section-horizontal-padding-tablet;
  }

  &__item-link {
    display: block;
    color: inherit;
    text-transform: uppercase;
    font-size: $size-3;
    padding: 1.25rem 0 $spacing-3;
    border-bottom: 2px solid currentColor;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--submenu {
      text-transform: initial;
      font-size: $size-5;
    }
  }
}

.mobile-nav-toggle {
  @include xl {
    display: none;
  }

  &__button {
    @extend %button-no-styling;
    color: $night;
    font-size: $size-5;
    height: 4rem;
    width: 4rem;
    margin-right: -$spacing-4;
  }

  &__icon-close {
    width: $size-5;
    height: $size-5;
  }
}
</style>

<style lang="scss">
// Helper offsetting elements used by anchor links to adjust for fixed header
// TODO: Replace with `scroll-padding-top` once iOS Support rolled out
.anchor-target {
  display: block;
  height: 5rem;
  margin-top: -5rem;
  visibility: hidden;
}
</style>
