<template>
  <section v-if="pageContent" class="choose-appointment">
    <div class="choose-appointment__wrapper">
      <p class="choose-appointment__title">
        Step {{ currentStepNumber }}: {{ pageContent.stepTitle }}
      </p>
      <Article
        v-if="isRepairAppointment && appointmentTypeCopy"
        :text-content="appointmentTypeCopy"
        class="choose-appointment__repair-info"
        no-margin
      />
      <Spinner v-if="slotsPending" spinner-text="Loading..." />
      <div v-else>
        <p class="choose-appointment__address">
          {{ pageContent.installationAddressText }} {{ fullAddress }}
        </p>
        <div v-if="!showNoSlotsError">
          <p class="choose-appointment__select-time-text">
            {{ pageContent.selectDateText }}
          </p>
          <DateTimePicker
            v-model="selectedAppointmentSlot"
            :days="days"
            :available-slots="availableSlots"
            :appointment-info-content="appointmentInfoContent"
          />
          <AppButton
            v-if="nextButtonProps"
            v-track="nextButtonProps.tracking"
            class="choose-appointment__next-button"
            :disabled="!validated"
            @click="nextStep"
          >
            <span> {{ nextButtonProps.label }} </span>
            <span class="customer-details__icon icon icon-arrow-right" />
          </AppButton>
        </div>
        <NoSlotsAvailable
          v-else
          :header="pageContent.noSlotsMessageHeader"
          :content="noSlotsAvailableContent"
          :button-props="backToAccountButtonProps"
        />
      </div>
    </div>
  </section>
  <EligibilityModal
    v-if="modal.isModalVisible"
    :show="modal.isModalVisible"
    :variation="modal.variation"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex"
import GatewayPortal from "@/services/GatewayPortal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Spinner from "@soenergy/frontend-library/src/components/Spinner.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import NoSlotsAvailable from "@/components/NoSlotsAvailable.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import EligibilityModal from "@/components/EligibilityModal.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import chooseAppointmentContent from "soenergy-cms-loader!?path=smbp/choose-appointment"

export default {
  components: {
    AppButton,
    NoSlotsAvailable,
    EligibilityModal,
    DateTimePicker,
    Spinner,
    Article,
  },
  mixins: [cmsPreviewMixin({ story: chooseAppointmentContent })],
  props: {
    currentStepNumber: {
      type: Number,
      default: null,
    },
  },
  emits: ["go-forward"],
  data() {
    return {
      availableSlots: [],
      days: [],
      slotsPending: false,
      showNoSlotsError: false,
      modal: {
        isModalVisible: false,
        variation: null,
      },
    }
  },
  computed: {
    ...mapState({
      meterEligibility: (state) => state.meterEligibility,
    }),
    ...mapGetters([
      "fullAddress",
      "validatedPostcode",
      "hasActiveAgreements",
      "isSwitchingAway",
      "hasSmartMeter",
      "hasTraditionalMeter",
      "isEligibleForInstall",
      "isEligibleForRepair",
      "appointmentType",
    ]),
    selectedAppointmentSlot: {
      get() {
        return this.$store.state.appointment.selectedAppointmentSlot
      },
      set(value) {
        this.$store.commit("SET_SELECTED_APPOINTMENT_SLOT", value)
      },
    },
    validated() {
      return (
        !!this.selectedAppointmentSlot?.date &&
        !!this.selectedAppointmentSlot?.time &&
        !this.slotsPending
      )
    },
    nextButtonProps() {
      return this.pageContent?.nextStepButton?.[0] ?? null
    },
    backToAccountButtonProps() {
      return this.pageContent?.backToAccountButton?.[0] ?? null
    },
    noSlotsAvailableContent() {
      return this.pageContent?.noSlotsMessage?.[0]?.textContent ?? null
    },
    appointmentInfoContent() {
      return this.pageContent?.appointmentInfoMessage?.[0]?.textContent ?? null
    },
    isRepairAppointment() {
      return this.$route.meta.appointmentType === "repair"
    },
    appointmentTypeCopy() {
      const meterEligibility = this.meterEligibility?.appointmentType
      const appointmentTypeCopies = this.pageContent.appointmentTypeCopies
      if (!meterEligibility) return null

      const appointmentCopy = appointmentTypeCopies
        .flatMap((copy) => copy.appointmentTypeCopy)
        .find((copy) => copy.name === meterEligibility)

      return appointmentCopy?.text?.content?.[0] ?? null
    },
  },
  mounted() {
    this.slotsPending = true
    this.checkEligibility()
  },
  methods: {
    handleSelectedAppointmentSlot(appointmentSlot) {
      this.selectedAppointmentSlot = appointmentSlot
    },
    nextStep() {
      this.$emit("go-forward")
    },
    fetchSlots() {
      GatewayPortal.getAvailableSlots(this.validatedPostcode)
        .then((response) => {
          if (response && response.data && response.data.days) {
            this.availableSlots = response.data.days
            this.days = response.data.days
              .filter((day) => day.slots.length)
              .map((day) => new Date(day.date))
          }
        })
        .catch((err) => {
          this.showNoSlotsError = true
          this.$store.commit("RESET_CALENDER")
          throw err
        })
        .finally(() => {
          if (!this.days.length) {
            this.showNoSlotsError = true
          }
          this.slotsPending = false
        })
    },
    checkEligibility() {
      if (!this.hasActiveAgreements || this.isSwitchingAway) {
        this.setModal("switchingAway")
        return
      } else if (
        this.hasSmartMeter &&
        this.hasTraditionalMeter &&
        !this.isRepairAppointment
      ) {
        this.setModal("hasSmartAndTraditionalMeters")
        return
      } else if (this.hasSmartMeter && !this.isRepairAppointment) {
        this.setModal("hasSmartMeter")
        return
      } else if (!this.isRepairAppointment && !this.isEligibleForInstall) {
        this.setModal("ineligible")
        return
      } else if (this.isRepairAppointment && !this.isEligibleForRepair) {
        this.setModal("repairIneligible")
        return
      }
      this.fetchSlots()
    },
    setModal(modalVariant) {
      this.modal.variation = modalVariant
      this.modal.isModalVisible = true
      this.$store.commit("RESET_CALENDER")
    },
  },
}
</script>

<style scoped lang="scss">
.choose-appointment {
  &__wrapper {
    margin-bottom: $space-16;

    @include md {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
    margin-bottom: $space-4;

    @include md {
      font-size: $font-size-6;
    }
  }

  &__address {
    font-size: $font-size-3;
    font-weight: $weight-medium;
    margin-bottom: $space-2;

    @include md {
      font-size: $font-size-5;
    }
  }

  &__select-time-text {
    font-size: $font-size-3;
    margin-bottom: $space-5;

    @include md {
      font-size: $font-size-4;
    }
  }

  &__next-button.button {
    position: fixed;
    bottom: $space-4;
    left: 50%;
    transform: translateX(-50%);
    z-index: zindex(sticky);
    width: 90%;

    @include md {
      width: 100%;
      position: static;
      transform: translateX(0);
      margin: 2rem 0;
    }
  }

  &__repair-info {
    font-size: $font-size-3;
    margin-bottom: $space-5;

    @include md {
      font-size: $font-size-4;
    }
  }
}
</style>
