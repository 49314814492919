import { sessionStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import {
  untestedMutations,
  untestedGetters,
} from "@/store/untested/modules/appointment"

dayjs.extend(utc)

export const state = {
  selectedDate: sessionStore.getItem("selectedDate") || null,
  selectedAppointmentSlot:
    JSON.parse(sessionStore.getItem("selectedAppointmentSlot")) || null,
}

export const mutations = {
  ...untestedMutations,
  // Tested mutations below
}

export const getters = {
  ...untestedGetters,
  // Tested getters below
}

export default {
  state,
  mutations,
  getters,
}
