import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import reportError from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/reportError"

const authenticate = async (request) => {
  const token = await AuthenticationService.getAccessTokenAndRenewIfNeeded()
  if (token) {
    request.headers.Authorization = `${token}`
  }
  return request
}

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ROOT_API,
  requestInterceptors: [authenticate],
  responseErrorInterceptors: [reportError],
})

export default {
  getInstallationInfo(juniferAccountId, juniferAccountNumber) {
    return apiClient.get(
      `accounts/${juniferAccountId}/installation-info?junifer_account_number=${juniferAccountNumber}`
    )
  },
  getCustomerEligibility(juniferAccountId) {
    return apiClient.get(`accounts/${juniferAccountId}/customer-eligibility`)
  },
  getCampaignStatus(juniferAccountId) {
    return apiClient.get(`accounts/${juniferAccountId}/campaign-status`)
  },
}
