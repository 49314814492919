import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: `${process.env.VUE_APP_API_GATEWAY_URL}/smart-meter/`,
  requestInterceptors: [authenticate],
})

export default {
  getAvailableSlots(postcode) {
    return apiClient.get(`available-slots?postcode=${postcode}`)
  },
  createAppointment(data, accountId) {
    return apiClient.post(`${accountId}/create-booking`, data)
  },
  cancelBooking(accountId, jobNumber) {
    return apiClient.post(`${accountId}/cancel-booking`, {
      jobNumber,
    })
  },
  updateBooking(data) {
    return apiClient.patch("update-booking", data)
  },
  getBookings(accountId) {
    return apiClient.get(`${accountId}/get-bookings`)
  },
  getMeterEligibility(accountId) {
    return apiClient.get(`${accountId}/meter-repair-eligibility`)
  },
}
