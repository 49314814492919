<template>
  <div class="no-slots">
    <FeedbackDialog
      class="no-slots__dialog"
      :header="header"
      variant="warning"
      full-width
    >
      <Article ref="activeAccountText" :text-content="content" no-margin />
    </FeedbackDialog>
    <NavButton
      v-if="buttonProps"
      class="no-slots__button"
      v-bind="buttonProps"
    />
  </div>
</template>

<script>
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import NavButton from "@soenergy/frontend-library/src/components/NavButton.vue"
import Mixpanel from "@soenergy/frontend-library/src/services/Mixpanel"

export default {
  components: {
    FeedbackDialog,
    Article,
    NavButton,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    header: {
      type: String,
      default: "",
    },
    buttonProps: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    Mixpanel.sendEvent("No slots available")
  },
}
</script>

<style scoped lang="scss">
.no-slots {
  &__dialog {
    margin-top: $space-6;
  }
  &__button {
    margin-top: $space-8;
  }
}
</style>
