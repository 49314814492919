import getDataDogReleaseVersion from "../../build-tools/getDataDogReleaseVersion"
import { safeJSONParse } from "../helpers/safeJSONParse"
/* 
  VERCEL_BRANCH is converted to string in the build process, so we need
  to check for the string "undefined" here.
*/
export const canInitializeDatadog = () =>
  !!process.env.VERCEL_BRANCH && process.env.VERCEL_BRANCH !== "undefined"

const sensitiveFieldNames = [
  "password",
  "checkPassword",
  "bank_account_number",
  "accountNumber",
  "sortCode",
  "sort_code",
  "token",
  "cardNumber",
  "card_number",
  "expiry",
  "code",
  "cardHolderName",
  "securityCode",
  "first_name",
  "last_name",
  "full_name",
  "accountName",
  "firstName",
  "lastName",
  "forename",
  "surname",
  "careOf",
  "phoneNumber1",
  "phoneNumber2"
]
const redactSensitiveFields = data => {
  if (Array.isArray(data)) {
    return data.map(item => redactSensitiveFields(item))
  }
  if (typeof data === "object" && data !== null) {
    const obfuscatedData = {}
    for (const fieldName in data) {
      if (data.hasOwnProperty(fieldName)) {
        const fieldValue = data[fieldName]
        if (typeof fieldValue === "object") {
          obfuscatedData[fieldName] = redactSensitiveFields(fieldValue)
        } else if (sensitiveFieldNames.includes(fieldName)) {
          obfuscatedData[fieldName] = "*REDACTED*"
        } else {
          obfuscatedData[fieldName] = fieldValue
        }
      }
    }
    return obfuscatedData
  }
  return data
}

export const setRequestMetadata = (event, error) => {
  const responseData = error?.response?.data
  const requestData = error?.config?.data
    ? safeJSONParse(error?.config?.data)
    : null
  const responseCode = error?.response?.status
  const endpointURL = error?.config?.url

  event.context = event.context || {}
  if (responseCode) event.context.responseCode = responseCode
  if (endpointURL) event.context.endpointURL = endpointURL
  if (responseData) {
    event.context.responseData = redactSensitiveFields(responseData)
  }
  if (requestData) {
    event.context.requestData = redactSensitiveFields(requestData)
  }
}
/**
 * Sanitizes a tag name for use with Datadog.
 * https://docs.datadoghq.com/getting_started/tagging/#define-tags
 *
 * @param {string} value - The tag name to sanitize.
 * @returns {string|undefined} The sanitized tag name.
 */
export const sanitizeTagName = value =>
  value?.toLowerCase().replace(/[^a-z0-9_:./-]/g, "_")

export const commonDatadogParams = {
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: "datadoghq.eu",
  env:
    process.env.VERCEL_BRANCH === "master"
      ? "production"
      : sanitizeTagName(process.env.VERCEL_BRANCH),
  service: process.env.VUE_APP_DATADOG_SERVICE_NAME,
  sessionSampleRate: 100,
  version: getDataDogReleaseVersion()
}
