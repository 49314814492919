const meterTypes = [
  { typeCode: "H", isSmartMeter: false, smetsVersion: null },
  { typeCode: "K", isSmartMeter: false, smetsVersion: null },
  { typeCode: "LAG_", isSmartMeter: false, smetsVersion: null },
  { typeCode: "LEAD_", isSmartMeter: false, smetsVersion: null },
  { typeCode: "MAIN_", isSmartMeter: false, smetsVersion: null },
  { typeCode: "N", isSmartMeter: false, smetsVersion: null },
  { typeCode: "NCAMR", isSmartMeter: false, smetsVersion: null },
  { typeCode: "NSS", isSmartMeter: false, smetsVersion: null },
  { typeCode: "NS", isSmartMeter: false, smetsVersion: null },
  { typeCode: "RCAMR", isSmartMeter: false, smetsVersion: null },
  { typeCode: "RCAMY", isSmartMeter: false, smetsVersion: null },
  { typeCode: "S", isSmartMeter: false, smetsVersion: null },
  { typeCode: "SPECL", isSmartMeter: false, smetsVersion: null },
  { typeCode: "T", isSmartMeter: false, smetsVersion: null },
  { typeCode: "CM", isSmartMeter: false, smetsVersion: null },
  { typeCode: "CR", isSmartMeter: false, smetsVersion: null },
  { typeCode: "ET", isSmartMeter: false, smetsVersion: null },
  { typeCode: "MT", isSmartMeter: false, smetsVersion: null },
  { typeCode: "PP", isSmartMeter: false, smetsVersion: null },
  { typeCode: "TH", isSmartMeter: false, smetsVersion: null },
  { typeCode: "U", isSmartMeter: false, smetsVersion: null },
  { typeCode: "S1", isSmartMeter: true, smetsVersion: 1 },
  { typeCode: "S2", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2A", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2B", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2C", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2AD", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2BD", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2CD", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2ADE", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2BDE", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "S2CDE", isSmartMeter: true, smetsVersion: 2 },
  { typeCode: "CONV", isSmartMeter: false, smetsVersion: null }
]

const isSmartMeter = meterType => {
  if (!meterType) {
    return false
  }
  const matchingMeter = meterTypes.find(
    meterSpec => meterSpec.typeCode == meterType
  )
  return matchingMeter ? matchingMeter.isSmartMeter : false
}

export { meterTypes, isSmartMeter }
