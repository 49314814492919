export const appointmentTypeMapping = {
  "meter-investigation": "FAULTS",
  "meter-reboot": "FAULTS",
  "meter-power-cycle": "FAULTS",
  "recommission-dual": "COMMISSION_JOIN",
  "recommission-elec": "COMMISSION_JOIN",
  "recommission-gas": "COMMISSION_JOIN",
  "meter-exchange-dual": "METER_EXCHANGE",
  "meter-exchange-elec": "METER_EXCHANGE",
  "meter-exchange-gas": "METER_EXCHANGE",
  install: "METER_EXCHANGE",
  "install-elec": "METER_EXCHANGE",
  "install-gas": "METER_EXCHANGE",
} as const
